import {
    email,
    required,
    maxLength,
    validURL,
    hasProperHeightAndWidth,
    validQualityMarkUrl,
} from "@/validation/validators";

export const getCompanyPageSettingsSchema = (model) => ({
    companyPageSettings: {
        email: { email, required },
        newlogo: model.newlogo
            ? {
                  hasProperHeightAndWidth: hasProperHeightAndWidth({
                      maxWidth: 400,
                      maxHeight: 400,
                  }),
              }
            : {},
        description: {
            maxLength: maxLength(1000),
        },
        facebookUrl: {
            validURL,
        },
        qualityMarks: {
            $each: {
                link: {
                    qualityMarkURLRequired(link, mark) {
                        return !(mark.enabled && !link && !mark.readOnly);
                    },

                    validURL(link, mark) {
                        const regex =
                            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
                        return !(mark.enabled && !regex.test(link) && !mark.readOnly);
                    },

                    validQualityMarkUrl(link, mark) {
                        const urlShapes = {
                            feedbackCommunity: "https://www.feedbackcommunity.nl/",
                            qShops: "https://www.qshops.org/",
                            thuiswinkel: "https://www.thuiswinkel.org/",
                            anvr: "https://www.keurmerk.info/",
                            keurmerkWebwinkelkeur: "https://www.webwinkelkeur.nl/",
                            keurmerkSafeshops: "https://label.safeshops.be/",
                            opiness: "https://opiness.nl/review/%name%/%anotherName%",
                        };

                        return validQualityMarkUrl({
                            link,
                            mark,
                            urlShape: urlShapes[mark.code],
                        })();
                    },
                },
            },
        },
    },
});
